import "./SASS/styles.scss";
import { Suspense, lazy } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { MainLoader, NotFound, Layout, Loader } from "./MainComponents";
import HomePage from "./Pages/HomePage/HomePage";

import {
  Dashboard,
  AddEditDashboardContent,
  Requests,
  AdminLayout,
  Codes,
  Students,
  EditStudent,
  DeletedStudents,
  AdminCourse,
  AdminCourses,
  AddEditCourse,
  Offers,
  AddEditOffer,
  AddEditMaterial,
  AddEditVideo,
  AddEditChooseQuestion,
  AddEditEssayQuestion,
  AdminQuizHomework,
  AdminExams,
  AdminAddEditExam,
  QuestionBank,
  AddEditCategory,
  Assistants,
  Reviews,
  Analytics,
  QuestionBankCategory,
  AddEditSession,
  ChatAdmin,
  VodafoneCashCodes,
  OffersCodes,
  CodesTracker,
  Whatsapp,
  AdminViewCourse,
  AdminProfile,
  AdminEditViewCourse,
  StudentSendWhatsappMessage,
  ViewStudent,
  Exception,
  AdminViewStudentAsignments,
  AdminAddStudentToCourse,
  AdminViewAllStudentsAssignments,
  AdminViewExam,
  Reports,
  LateStudents,
  PointsCodes,
} from "./Pages/Admin";
import {
  StudentCourses,
  SingleCourse,
  StudentOffer,
  QuizNew,
  Register,
  ResetPassword,
  SignIn,
  Profile,
  Exams,
  SingleExamPage,
  MyWallet,
} from "./Pages/Student";
import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes.jsx";
import AddEditAssistant from "./Pages/Admin/Dashboard/Assistants/AddEditAssistant";
import { useAuthState } from "./MainComponents/GlobalContext";
import ModalAnswer from "./Pages/modalAnswer/ModalAnswer";

import PrivacyPolicyPage from "./Pages/LandingPage/PrivacyPolicyPage.jsx";
const LazyLandingPage = lazy(() => import("./Pages/LandingPage/LandingPage"));

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------
    {
      path: "/",
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/home"
          }`}
          isAllowed={!AuthState.userData}
        >
          <Suspense fallback={<Loader />}>
            <LazyLandingPage />
          </Suspense>
        </ProtectedAdminRoutes>
      ),
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    //!--------Student Layout--------

    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <Layout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/courses",
          element: <StudentCourses />,
        },
        {
          path: "/home/modal-answer/:contentID",
          element: <ModalAnswer />,
        },
        {
          path: "/home/previous-homework-modal-answer/:contentID",
          element: <ModalAnswer previousHomework />,
        },
        {
          path: "/courses/:courseID",
          element: <SingleCourse />,
        },
        {
          path: "/courses/offers/:offerID",
          element: <StudentOffer />,
        },
        {
          path: "/exams",
          element: <Exams />,
        },
        {
          path: "/exams/:examID",
          element: <SingleExamPage />,
        },
        {
          path: "/exams/solve-exam/:ID",
          element: <QuizNew exam />,
        },
        {
          path: "/exams/modal-answer/:ID",
          element: <ModalAnswer exam />,
        },
        //! -------Profile pages --------
        {
          path: "/home/profile/",
          element: <Profile />,
        },
        //! -------Profile pages --------
        {
          path: "/home/my-wallet",
          element: <MyWallet />,
        },

        //! -------Quiz page--------

        {
          path: "/home/quiz/:ID",
          element: <QuizNew />,
        },
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        {
          path: "/home/previous-homework/:courseID",
          element: <QuizNew homework />,
        },
      ],
    },

    //! -------Admin Layout--------

    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/not-found"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <AdminLayout />{" "}
        </ProtectedAdminRoutes>
      ),
      children: [
        //!--------- dashboard Routes --------------

        {
          path: "/admin/dashboard",
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={true}
              // isAllowed={
              //   AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              //   AuthState.userData?.admin?.permissions?.some((permission) =>
              //     permission.name.startsWith("post")
              //   )
              // }
            >
              <Dashboard />
            </ProtectedAdminRoutes>
          ),
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("post_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/dashboard/edit-post/:postID",
              element: <AddEditDashboardContent edit />,
            },
          ],
        },
        //! -------Codes Routes--------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/codes",
              element: <Codes />,
            },
            {
              path: "/admin/points-codes",
              element: <PointsCodes />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("activationcode")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/activation-code",
              element: <Codes activation />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_addvodafone")
                ) ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_update_vodafone")
                ) ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_delete_vodafone")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/vodafone-cash-codes",
              element: <VodafoneCashCodes />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/offers-codes",
              element: <OffersCodes />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_tracker")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes-tracker",
              element: <CodesTracker />,
            },
          ],
        },

        //! ------- Whatsapp Routes -------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("send_whatsapp")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/whatsapp",
              element: <Whatsapp />,
            },
          ],
        },

        //! -------Students Routes--------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students",
              element: <Students />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/edit-student/:studentID",
              element: <EditStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/view-student/:studentID",
              element: <ViewStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request_accept")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/deleted",
              element: <DeletedStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={true}
              // isAllowed={
              //   AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              //   AuthState.userData?.admin?.permissions?.some((permission) =>
              //     permission.name.startsWith("request_accept")
              //   )
              // }
            />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/send-whatsapp/:studentID",
              element: <StudentSendWhatsappMessage />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_send_message")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/students/send-message/:studentID",
              element: <StudentSendWhatsappMessage message />,
            },
          ],
        },
        //!----- Question Bank Routes -------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank",
              element: <QuestionBank />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith(
                    "questionbank_viewquestionbycategory"
                  )
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID",
              element: <QuestionBankCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addcategory")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/add-category",
              element: <AddEditCategory />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_addquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/:categoryID/add-essay-question",
              element: <AddEditEssayQuestion />,
            },
            {
              path: "/admin/question-bank/:categoryID/add-choose-question",
              element: <AddEditChooseQuestion />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("questionbank_editquestion")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/question-bank/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/question-bank/:categoryID/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },
        //!----- Courses Routes -------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses",
              element: <AdminCourses admin />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/view/:courseID",
              element: <AdminViewCourse />,
            },
            {
              path: "/admin/courses/view/edit/:ID",
              element: <AdminEditViewCourse />,
            },
            {
              path: "/admin/courses/view/view-asignments/:ID",
              element: <AdminViewStudentAsignments />,
            },
            {
              path: "/admin/courses/view/:courseID/add-student",
              element: <AdminAddStudentToCourse />,
            },
            {
              path: "/admin/courses/view/:courseID/all-assignments",
              element: <AdminViewAllStudentsAssignments />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/:courseID",
              element: <AdminCourse />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/add-course",
              element: <AddEditCourse />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/edit-course/:courseID",
              element: <AddEditCourse edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_session_update")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/add-session/:courseID",
              element: <AddEditSession />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/edit-session/:sessionID",
              element: <AddEditSession edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("offer")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/offers",
              element: <Offers />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("offer_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/offers/add-offer",
              element: <AddEditOffer />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("offer_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/offers/edit-offer/:offerID",
              element: <AddEditOffer edit />,
            },
          ],
        },

        //!----- Exception Routes -------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exceptions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exception",
              element: <Exception />,
            },
          ],
        },
        //!----- Reports Routes -------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("show_reports")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/reports",
              element: <Reports />,
            },
          ],
        },
        //!----- Late Students Routes -------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("show_reports")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/late-students",
              element: <LateStudents />,
            },
          ],
        },

        //!----- Sessions Routes -------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_material_permissions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/attachment/:materialID",
              element: <AddEditMaterial />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_videos_permissions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/video/:videoID",
              element: <AddEditVideo />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_quizzes_permissions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/quiz/:ID",
              element: <AdminQuizHomework />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_homeworks_permissions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/homework/:ID",
              element: <AdminQuizHomework homework />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_homeworks_permissions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/homework/:homeworkID/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/courses/homework/:homeworkID/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_quizzes_permissions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/courses/quiz/:quizID/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit />,
            },
            {
              path: "/admin/courses/quiz/:quizID/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit />,
            },
          ],
        },
        //!----- Exams Routes -------------
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams",
              element: <AdminExams />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/add-exam",
              element: <AdminAddEditExam />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/edit-exam/:ID",
              element: <AdminAddEditExam edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/all-students/:ID",
              element: <AdminViewExam />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_addquestions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/:ID",
              element: <AdminQuizHomework exam />,
            },
          ],
        },
        //!---- reviews route ----
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("reviews")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/reviews",
              element: <Reviews />,
            },
          ],
        },
        //!---- Assistants route ----

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin"
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
        //!---- Analytics route ----
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("analytics")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/analytics",
              element: <Analytics />,
            },
          ],
        },
        //!---- Chats route ----
        // edit chat permissions
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("ask")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/chat",
              element: <ChatAdmin />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("superadmin_chats")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/all-chats",
              element: <ChatAdmin superadmin />,
            },
          ],
        },
        //!---- Requests route ----
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/requests",
              element: <Requests />,
            },
          ],
        },
      ],
    },
    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/home"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn
        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },
        //!Register
        {
          path: "/register",
          element: <Register />,
        },
        //! -------reset password page--------
        {
          path: "/reset_password",
          element: <ResetPassword />,
        },
      ],
    },

    //!NotFound
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<MainLoader />} />;
}

export default App;
